// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { ApiGatewayPathBuilder, CloudRunPathBuilder, Env, IEmployerEnvironment } from 'shared-lib'

const env: Env = 'demo'
const projectNumber = '861440716888'
const projectHash = 'vn645xctzq'
const pathBuilder = new ApiGatewayPathBuilder(env)
const cloudRunPathBuilder = new CloudRunPathBuilder(projectHash)

export const environment: IEmployerEnvironment = {
  production: false,
  env,
  firebaseConfig: {
    apiKey: 'AIzaSyBRNqXm82BMKEVWhs9uNjMkwnWTCAOcFoE',
    authDomain: 'cnect-demo-100.firebaseapp.com',
    projectId: 'cnect-demo-100',
    storageBucket: 'cnect-demo-100.appspot.com',
    messagingSenderId: projectNumber,
    appId: '1:861440716888:web:c43bc6dcb8b8c292bbda2d',
  },
  services: {
    cloudFunctions: 'https://us-central1-cnect-demo-100.cloudfunctions.net',
    applicationHistory: pathBuilder.build('applicationHistory'),
    content: pathBuilder.build('content'),
    employer: pathBuilder.build('employer'),
    events: pathBuilder.build('events'),
    jobs: pathBuilder.build('jobs'),
    registration: pathBuilder.build('registration'),
    user: pathBuilder.build('user'),
    auth: pathBuilder.build('auth'),
    files: pathBuilder.build('files'),
    messaging: pathBuilder.build('messaging'),
    virtualDialogue: pathBuilder.build('virtualDialogue'),
    access: pathBuilder.build('access'),
    jobsIntegration: pathBuilder.build('jobsIntegration'),
    notifications: pathBuilder.build('notifications'),
    customer: pathBuilder.build('customer'),
    jobsAutomation: pathBuilder.build('jobsAutomation'),
    candidatePool: pathBuilder.build('candidatePool'),
    sse: cloudRunPathBuilder.build('sse', false),
    product: pathBuilder.build('product'),
  },
  // We can turn authentication off in case we are using this application inside and SDK and do not want to have any authentication
  authentication: true, // Look to the auth guard for use of this value
  themesEnabled: true,
  v2ReCaptcha: '6LdL9f4bAAAAAFVMFNz49EExxbLn-T9KMrDIXg1W',
  tenantId: 'B2B-USER-48j0o',
  algoliaAppId: '3YG4F5RXCG',
  cdnCname: 'demo-content.cnected.com',
  basePlatformHost: 'demo-employer.cnected.com',
  candidateExternalJobUrl: 'https://demo-network.cnected.com/#/landing/job/',
  candidateEmailActionRoute: 'https://demo-network.cnected.com/#/auth/actions',
  careersPageBaseLink: 'https://demo-careers.cnected.com/',
  employerBaseUrl: 'https://demo-employer.cnected.com/',
  employerEmailSuccessRoute: 'https://demo-employer.cnected.com/#/auth/action-success/verifyEmail',
  passwordRecoveryEmailTemplateId: 'sfIqysHX4k910Rq0K4r3',
  emailVerificationEmailTemplateId: '5KYG0zYnsdtR0DLtgjwv',
  betaMessageId: '1HV5MRdznzPO0ivgBbom',
  version: '503b664',

  tenant: {
    appConfigBaseURL: 'https://demo-content.cnected.com/assets/bootstrap',
    defaultTenant: 'demo-employer.cnected.com',
  },
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
